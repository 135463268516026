<template>
    <v-container fluid>
        <v-row>
            <v-col 
                cols="6"
                lg="10"
                md="8"
                sm="6"
            >
                <h3>Cidade</h3>
            </v-col>
            <v-col 
                cols="2" 
                style="text-align: right;"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            :to="{ name: 'cidadeForm', params: {id: 0} }"
                        >
                            Nova Cidade
                        </v-btn>
                    </template>
                    <span>Nova Cidade</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        label="Pesquisa Rápida"
                        append-outer-icon="mdi mdi-magnify"
                        single-line
                        outlined
                        @click="options.page=1;"
                        @click:append-outer="getRegisters"
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    no-data-text="Nenhum registro encontrado"
                    :footer-props="{
                        'items-per-page-text':'Registros por Página',
                        'items-per-page-all-text':'Todos',
                        pageText: '{0}-{1} de {2}'
                    }"
                >

                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id" 
                            :link="'cidadeForm'"
                            :showButtons="{
                                edit: true,
                                delete: true,
                            }"
                            @confirmDelete="confirmDelete" />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionDialog from "@/components/Layout/ActionDialog";

    export default ({

        components: {
            ActionList: ActionList,
            ActionDialog: ActionDialog
        },

        mixins: [Helpers],

        data: () => ({

            loading: false,

            statusId: 0,

            filter: {
                fastSearch: ''
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: "Pais", value: "paisSelected.descricao", sortable: true, size: "40%" },
                { text: "Descrição", value: "descricao", sortable: true, size: "45%" },
                { text: "Ações", value: "action", sortable: false, align: "center" }
            ],

            options: { rowsPerPage: 10, page: 1 },

            listcidade: []
        }),

        computed: {

            filteredSearch: function() {
                return this.listcidade.filter((cidadeFilter) => {
                    
                    let filter = this.filter.fastSearch.toLowerCase();

                    var paisSelected = cidadeFilter.paisSelected.descricao.toLowerCase().match(filter)
                    var descricao = cidadeFilter.descricao.toLowerCase().match(filter)
                    var id = cidadeFilter.id.toString().match(filter);

                    if(paisSelected != null) { return paisSelected; }
                    else if(descricao != null) { return descricao; }
                    else { return id; }
                });
            }
        },

        methods: {
            
            async getRegisters() {

                this.listcidade = await this.$store.dispatch("cidadeModule/List");
            },

            confirmDelete(id) {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmação',
                    bodyText: 'Você irá EXCLUIR essa CIDADE, confirma sua decisão?',
                    methodConfirm: this.delete,
                    params: id
                };
            },

            async delete(id) {

                this.showLoading();

                let result = await this.$store.dispatch("cidadeModule/Delete", id);

                if (result.success) {
                    this.showToast("success", "Sucesso!", result.message);
                }
                else {
                    this.showToast("error", "Erro!", result.message);
                }

                this.getRegisters();

                this.hideLoading();
            },
        },

        async created() {
            this.showLoading();

            await this.getRegisters();

            this.hideLoading();
        }
    })
</script>
